html, body {
  height: 100%;
}

#root {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.admin-title {
  box-sizing: border-box;
  padding-left: 1em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.leaflet-container {
  height: 600px;
  width: 100%;
}

.userView {
  .background {
    img {
      width: 100%;
      height: 100%;
    }
  }
}

#modal-root {
  position: relative;
  z-index: 999;
}

.myModal {
  background-color: rgba(128, 0, 0, 0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3em;
  font-weight: bolder;
  color: #a00000;
}

@font-face {
  font-family: 'luckiest_guyregular';
  src: url('/fonts/luckiestguy-webfont.woff') format('woff'),
       url('/fonts/luckiestguy-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}
