
.lostRoundWrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: rgba(64,0,0,0.7);
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lostRound {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 34rem;
    height: 24rem;
    background-image: url(/ui/error-dialog.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
}

.lostRoundText {
    position: relative;
    top: -1rem;
    margin: 0;
    font-family: 'luckiest_guyregular';
    font-weight: normal;
    font-size:3rem;
}

.lostRoundDetails {
    display: inline-block;
    font-size: 1rem;
    margin: 0;
    ul {
      list-style-type: none;
      padding: 0; 
    }
}

.lostRoundButtonText {
    font-family: 'luckiest_guyregular';
    font-weight: normal;
    font-size:2rem;
    position: relative;
    bottom: -3.8rem;
    width: 100%;
    text-align: center;
    display: block;
}
