.epochGameBoard{
    position: fixed;
    background-color: rgba(0,0,0,0.3);
    width: 100vw;
    height: 100vh;
    z-index: 500;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.distributorArea {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 28rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.distributorCardArea {
    display: flex;
    justify-content: center;
    width: 100%;
    background-image: url('/blackboard.jpg');
}

.categoriesArea {
    width: 50%;
    right: 0;
    height: 100%;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(1, 1fr);
    display: grid;
    background-image: url('/blackboard.jpg');
}

.categoriesAreaTwoCols {
    width: 50%;
    right: 0;
    height: 100%;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    background-image: url('/blackboard.jpg');
}

.topDescription {
    padding: 1rem;
    min-height: 2rem;
    color: white;
    width: 100%;
}

.bottomDescription {
    padding: 1rem 3rem 1rem 3rem;
    min-height: 2rem;
    color: white;
    background-image: url('/blackboard.jpg');
    width: 100%;
    text-align: center;
}

@keyframes fade-out {
    from {
        opacity: 1;
        transform: scale(10);
    }
    to {
        opacity: 0;
        transform: scale(0);
    }
}

@keyframes shake {
    5% {
        font-size: 5rem;
    }
    10%, 35%, 90% {
        transform: translate3d(-1px, 0, 0);
        opacity: 1;
    }

    20%, 45%, 80% {
        transform: translate3d(2px, 0, 0);
        opacity: 1;
    }

    30%, 50%, 15%, 70% {
        transform: translate3d(-4px, 0, 0);
        opacity: 1;
    }

    25%, 40%, 60% {
        opacity: 1;
        transform: translate3d(4px, 0, 0);
    }

    100% {
        opacity: 0;
    }
}
