
.headerBar {
    display: block;
    font-weight: bolder;
    font-size: 3rem;
    color: rgba(180,160,120,1);
    background-color: rgba(180,160,120,0.2);
    text-align: center;
    width: 100%;
    height: 7rem;
}

.headerTitle {
    display: inline-block;
    font-weight: bolder;
    font-size: 3rem;
    height: 5rem;
    text-align: center;
    padding-top: 1rem;
}

.headerLeftBlock {
    width: 5rem;
    display: inline-block;
}
.avatar {
    margin-top: 1rem;
    margin-right: 1rem;
    float: right;
    background: white;
    display: block;
    background-image: url('/avatars/1_prehistorique.png');
    width: 5rem;
    height: 5rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5rem;
}

.avatarLevel {
    background: white;
    border-radius: 1rem;
    border: 1px solid rgba(120, 100, 60, 0.8);
    font-size: 0.7rem;
    font-weight: normal;
    color: black;
    margin-top: 4.5rem;
}