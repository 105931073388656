@mixin modal-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10000;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin modal-dialog {
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 45rem;
    height: 30rem;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding-top: 5rem;
    cursor: pointer;

}

.infoModalWrapper {
    @include modal-wrapper;
    background-color: rgba(0,48,64,0.7);
}

.infoModalBackground {
    @include modal-dialog;
    background-image: url(/ui/main-dialog.png);
}

.alertModalWrapper {
    @include modal-wrapper;
    background-color: rgba(64,0,0,0.7);
}

.alertModalBackground {
    @include modal-dialog;
    background-image: url(/ui/error-dialog.png);
}

.okModalWrapper {
    @include modal-wrapper;
    background-color: rgba(0,64,0,0.7);
}

.okModalBackground {
    @include modal-dialog;
    background-image: url(/ui/green-dialog.png);
}

.warningModalWrapper {
    @include modal-wrapper;
    background-color: rgba(64,32,0,0.7);
}

.warningModalBackground {
    @include modal-dialog;
    background-image: url(/ui/warning-dialog.png);
}

.noButtonModalWrapper {
    @include modal-wrapper;
    background-color: rgba(64,64,64,0.7);
}

.noButtonModalBackground {
    @include modal-dialog;
    background-image: url(/ui/main-dialog-no-button.png);
}

.modalButtonText {
    font-family: 'luckiest_guyregular';
    font-weight: normal;
    font-size:2rem;
    position: relative;
    bottom: 2rem;
    width: 100%;
    text-align: center;
    display: block;
}

.modalSeparator {
    margin: auto;
}