
.bottomArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    width: 100%;
    min-height: 12.8rem;
}

.score {
    display: flex;
    font-family: 'luckiest_guyregular';
    font-size: 2rem;
    color: white;
    -webkit-filter: drop-shadow(0px 0px 5px rgba(0,0,0,0.7));
    filter: drop-shadow(0px 0px 5px rgba(0,0,0,0.7));
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
}

.scoreArea {
    border: 1px solid white;
}

@mixin scoreAreaGeneric {
    display: block;
    position: absolute;
    font-family: 'luckiest_guyregular';
    top: 50%;
    opacity: 0;
}

.scoreArea > .normal:not(:first-child) {
    @include scoreAreaGeneric;
    font-size: 3rem;
    color: rgba(70, 220, 90, 0.9);
    -webkit-text-stroke: 3px rgba(40, 140, 50, 0.9);
    animation: fade-out ease-in 0.3s;
}
.scoreArea > .critical:not(:first-child) {
    @include scoreAreaGeneric;
    font-size: 7rem;
    color: rgba(240, 220, 90, 0.9);
    -webkit-text-stroke: 3px rgba(240, 140, 50, 0.9);
    animation: shake ease-in 1s;
}
