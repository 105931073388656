.appBoard {
    width: 100%;
    height: 100%;
}


.chButtonWrapper {
    margin-top: 2%;
}

.checkButtonWrapper {
    margin-top: 2%;
}

.cardBoard {
    height: 60%;
}

