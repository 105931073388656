
.background {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    flex-direction: column;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.2);
    top: 0;
    left: 0;
}

.cardsWonContainer {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    & > div {
        margin: 1rem;
    }
}

.cardsWon {
    font-family: 'luckiest_guyregular';
    font-size: 3rem;
    color: white;
    -webkit-filter: drop-shadow(0px 0px 5px rgba(0,0,0,0.7));
    filter: drop-shadow(0px 0px 5px rgba(0,0,0,0.7));
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    flex-direction: column;
}

.cardBoard {
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 100%;
    padding: 2rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-image: url('/ui/background-green.png');
    background-size: cover;
    border: 5px solid black;
    border-radius: 2rem;
    z-index: 1000;

    & > div {
        box-shadow: 10px 10px 10px black;
    }
}

.cardBoardOverlay {
    position: fixed;
    z-index: 1200;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    cursor: pointer;
}

@mixin wizardScreenOverlay {
    border: 1px solid white;
    margin: 1rem;
    width: 28rem;
    height: 28rem;
    font-family: 'luckiest_guyregular';
}
.congratulationsScreen {
    @include wizardScreenOverlay;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;


}