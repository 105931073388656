.menuWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url('/ui/map.jpg');
  background-position: 0 0;
  animation: scrollGood 600s linear infinite;


  @keyframes scrollGood {
    0% {
      background-position: 0% 0%;
      background-color: rgba(0, 0, 0, 1);
    }

    2% {

      background-color: rgba(0, 0, 0, 0);
    }

    100% {
      background-position: 100% 100%;
    }
  }
}

.appTitle {
  font-size: 7rem;
  font-weight: bolder;
  font-family: 'luckiest_guyregular';
  color: white;

  margin-top: 1rem;
  margin-bottom: 5rem;
  height: 10rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  text-shadow: 0px -6px 0 #212121,
    0px -6px 0 #212121,
    0px 6px 0 #212121,
    0px 6px 0 #212121,
    -6px 0px 0 #212121,
    6px 0px 0 #212121,
    -6px 0px 0 #212121,
    6px 0px 0 #212121,
    -6px -6px 0 #212121,
    6px -6px 0 #212121,
    -6px 6px 0 #212121,
    6px 6px 0 #212121,
    -6px 18px 0 #212121,
    0px 18px 0 #212121,
    6px 18px 0 #212121,
    0 19px 1px rgba(0, 0, 0, .1),
    0 0 6px rgba(0, 0, 0, .1),
    0 6px 3px rgba(0, 0, 0, .3),
    0 12px 6px rgba(0, 0, 0, .2),
    0 18px 18px rgba(0, 0, 0, .25),
    0 24px 24px rgba(0, 0, 0, .2),
    0 36px 36px rgba(0, 0, 0, .15);

  color: lightgray;
  position: relative;
}

.appTitleGrey {
  height: 50%;
  position: absolute;
  color: white;
  overflow: hidden;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}


.slideInBottom {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  background-image: url(/lernie-481px.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 400px;
  height: 300px;
  position: fixed;
  bottom: 0px;
  right: 0px;
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(300px);
    transform: translateY(300px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(300px);
    transform: translateY(300px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.bubble {
  background-image: url(/ui/bubble-dot.png);
  width: 32rem;
  height: 16rem;
  background-size: contain;
  background-repeat: no-repeat;
  position: fixed;
  right: 16rem;
  bottom: 3.5rem;
  z-index: 1000;
  -webkit-animation: bubble-text 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: bubble-text 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

}

.bubbleText {
  display: block;
  margin-top: 4.5rem;
  margin-left: 1rem;
  width: 21rem;

}

@keyframes bubble-text {
  0% {
    opacity: 0;
  }

  90% {
    opacity: 1;
  }
}
