.cardTitle {
  color: white;
  background-color: #444;
  font-weight: bold;
  width:100%;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding-top: 0.1rem;
  padding-bottom: 0.3rem;
  font-size: 1.2rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  transform: rotate(-1deg) scale(1.07,1.07);
  position: relative;
  top: -0.5rem;
  height: 3rem;
  border-bottom: 1px solid white;
}