
.successWrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: rgba(0,48,64,0.7);
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.success {
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 45rem;
    height: 32rem;
    background-image: url(/ui/main-dialog-no-button.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding-top: 5rem;
    cursor: pointer;
}

.successSeparator {
    margin: auto;
}
.successText {
    margin: 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-family: 'luckiest_guyregular';
    font-weight: normal;
    font-size:3rem;
}
.successDetails {
    display: flex;
    padding-top: 2rem;
    justify-content: center;
    align-items: center;
    width: 28rem;
    font-size: 1.3rem;
    margin: 0;
}

.successButtonText {
    font-family: 'luckiest_guyregular';
    font-weight: normal;
    font-size:2rem;
    position: relative;
    bottom: 3.8rem;
    width: 100%;
    text-align: center;
    display: block;
}

.cardIcon {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    background-image: url(/ui/card-icon.png);
    background-position: center;
    background-size: cover;
    margin-right: 0.2rem;
}

.energyIcon {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    background-image: url(/ui/energy-icon.png);
    background-position: center;
    background-size: cover;
    margin-right: 0.2rem;
}

.strIcon {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    background-image: url(/ui/str-icon.png);
    background-position: center;
    background-size: cover;
    margin-right: 0.2rem;
}

.noDateIcon {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    background-image: url(/ui/no-date-icon.png);
    background-position: center;
    background-size: cover;
    margin-right: 0.2rem;
}

.heartIcon {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    background-image: url(/ui/heart-icon.png);
    background-position: center;
    background-size: cover;
    margin-right: 0.2rem;
}

.indicator {
    display: inline-block;
    width: 4rem;
}

.label {
    display: block;
    padding-top: 0.8rem;
    font-size: 1.3rem;
    font-family: 'luckiest_guyregular';
    font-weight: 100;
}

.inputField {
    display: block;
    font-size: 1.3rem;
}

.button {
    text-align: center;
    width: 35%;
    border: 1px solid #ccc;
    height: 2rem;
    margin: 0.5rem;
}

.form {
    display: flex;
    width: 25rem;
    justify-content: center;
    position: relative;
    top: -1rem;
}
.formInner {
    width: 15rem;
}

.registerSeparator {
    margin: auto;
}

.bottomBar {
    width: 700px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.laterButton {
    background-image: url(/ui/cancel-button.png);
    width: 115px;
    height: 114px;
    margin-right: 1rem;
}

.previousButton {
    background-image: url(/ui/previous-button.png);
    width: 115px;
    height: 114px;
    margin-left: 1rem;
}

.previousButtonDisabled {
    background-image: url(/ui/previous-disabled-button.png);
    width: 115px;
    height: 114px;
    margin-left: 1rem;
}
.nextButton {
    background-image: url(/ui/next-button.png);
    width: 115px;
    height: 114px;
    margin-left: 1rem;
}

.nextButtonDisabled {
    background-image: url(/ui/next-disabled-button.png);
    width: 115px;
    height: 114px;
    margin-left: 1rem;
}

.okButton {
    background-image: url(/ui/ok-button.png);
    width: 115px;
    height: 114px;
    margin-left: 1rem;
}

.registerFormTitle {
    margin: 0;
    margin-top: 2rem;
    font-family: 'luckiest_guyregular';
    font-weight: normal;
    font-size:2.5rem;
    text-align: center;
}

.registerFormParagraph {
    font-size: 1rem;
    width: 30rem;
}

.registerFormFormParagraph {
    font-size: 1rem;
    width: 20rem;
}

.registerFormFormParagraphEmphasis {
    font-size: 1.5rem;
    width: 20rem;
    text-align: center;
}

.registerFormDialog {
    display: flex;
    width: 30rem;
    flex-direction: column;
    align-items: center;
}

.errorMessage {
    font-size: 1.2rem;
    font-weight: bolder;
    color: #e6a3a3;
}