.cardValueGlow {
  width: 0.2rem;
  height: 0.2rem;
  background-color: rgba(231,197,132,0.7);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  left: 0.1rem;
  top: -1.3rem;
}
.cardValueContainer {
  display: inline-block;
  width: 100%;
  text-align: center;
  position: relative;
  top: -0.5rem;
}

.cardValueOuter{
  width: 1.2rem;
  height: 1.6rem;
  background-color: #ccc;
  border-radius: 50%;
  display: inline-block;
  border: 2px solid rgba(231,197,132,0.6);
  box-shadow: 1px 1px 0.5px 0.5px rgba(71, 57, 31, 0.7);
}

@mixin cardValueGeneric {
  margin: 0 auto;
  width: 1rem;
  height: 1.4rem;
  background-size:cover;
  background-position: center;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid rgba(32, 30, 26, 0.2);
}

.cardValue-level1 {
  @include cardValueGeneric;
  background-image: url(/card-levels/1-tin.png);
}

.cardValue-level2 {
  @include cardValueGeneric;
  background-image: url(/card-levels/2-serpentine.png);
  box-shadow:
  inset -1px 1px 1px 1px white,  /* inner white */
  inset 0 0 10px 5px rgba(0,160,0,1),  /* inner white */
  0px 0px 10px 5px rgba(0,192,0,0.8);
}

.cardValue-level3 {
  @include cardValueGeneric;
  background-image: url(/card-levels/3-malachite.png);
}

.cardValue-level4 {
  @include cardValueGeneric;
  background-image: url(/card-levels/4-azurite.png);
}

.cardValue-level5 {
  @include cardValueGeneric;
  background-image: url(/card-levels/5-lapislazulis.png);
}

.cardValue-level6 {
  @include cardValueGeneric;
  background-image: url(/card-levels/6-apatite.png);
}

.cardValue-level7 {
  @include cardValueGeneric;
  background-image: url(/card-levels/7-obsidienne.png);
}

.cardValue-level8 {
  @include cardValueGeneric;
  background-image: url(/card-levels/8-turquoise.png);
}

.cardValue-level9 {
  @include cardValueGeneric;
  background-image: url(/card-levels/9-opale.png);
}


.cardValue-level10 {
  @include cardValueGeneric;
  background-image: url(/card-levels/10-hematite.png);
}

.cardValue-level11 {
  @include cardValueGeneric;
  background-image: url(/card-levels/11-sunstone.png);
}

.cardValue-level12 {
  @include cardValueGeneric;
  background-image: url(/card-levels/12-moonstone.png);
}


.cardValue-level13 {
  @include cardValueGeneric;
  background-image: url(/card-levels/13-agate.png);
}


.cardValue-level14 {
  @include cardValueGeneric;
  background-image: url(/card-levels/14-peridot.png);
}


.cardValue-level15 {
  @include cardValueGeneric;
  background-image: url(/card-levels/15-jaspe.png);
}


.cardValue-level16 {
  @include cardValueGeneric;
  background-image: url(/card-levels/16-jade.png);
}


.cardValue-level17 {
  @include cardValueGeneric;
  background-image: url(/card-levels/17-aventurine.png);
}


.cardValue-level18 {
  @include cardValueGeneric;
  background-image: url(/card-levels/18-zircon.png);
}


.cardValue-level19 {
  @include cardValueGeneric;
  background-image: url(/card-levels/19-quartz.png);
}


.cardValue-level20 {
  @include cardValueGeneric;
  background-image: url(/card-levels/20-onyx.png);
}


.cardValue-level21 {
  @include cardValueGeneric;
  background-image: url(/card-levels/21-eyeoftiger.png);
}


.cardValue-level22 {
  @include cardValueGeneric;
  background-image: url(/card-levels/22-citrine.png);
}


.cardValue-level23 {
  @include cardValueGeneric;
  background-image: url(/card-levels/23-amethyst.png);
}


.cardValue-level24 {
  @include cardValueGeneric;
  background-image: url(/card-levels/24-tourmaline.png);
}


.cardValue-level25 {
  @include cardValueGeneric;
  background-image: url(/card-levels/25-grenat.png);
}


.cardValue-level26 {
  @include cardValueGeneric;
  background-image: url(/card-levels/26-spinelle.png);
}


.cardValue-level27 {
  @include cardValueGeneric;
  background-image: url(/card-levels/27-morganite.png);
}


.cardValue-level28 {
  @include cardValueGeneric;
  background-image: url(/card-levels/28-heliodore.png);
}


.cardValue-level29 {
  @include cardValueGeneric;
  background-image: url(/card-levels/29-aigue-marine.png);
}


.cardValue-level30 {
  @include cardValueGeneric;
  background-image: url(/card-levels/30-topaze.png);
}


.cardValue-level31 {
  @include cardValueGeneric;
  background-image: url(/card-levels/31-emerald.png);
}


.cardValue-level32 {
  @include cardValueGeneric;
  background-image: url(/card-levels/32-alexandrite.png);
}


.cardValue-level33 {
  @include cardValueGeneric;
  background-image: url(/card-levels/33-saphir.png);
}


.cardValue-level34 {
  @include cardValueGeneric;
  background-image: url(/card-levels/34-rubis.png);
}


.cardValue-level35 {
  @include cardValueGeneric;
  background-image: url(/card-levels/35-diamond.png);
}


.cardValue-level36 {
  @include cardValueGeneric;
  background-image: url(/card-levels/36-orichalque.png);
}


.cardValue-level37 {
  @include cardValueGeneric;
  background-image: url(/card-levels/37-iron.png);
}
