
.game {
  background-color: black;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(/blackboard.jpg)
}

.mapWrapper {
  height: 100vh;
  width: 100vw;
}

.map {
  height: 100vh;
  width: 100vw;
}
.myCircleMask {
  clip-path: inset(10px 20px 30px 40px);
  

}

.shadowfilter {
  -webkit-filter: drop-shadow(0px 0px 10px rgb(255, 240, 110));
   filter: drop-shadow(0px 0px 10px rgb(255, 240, 110));
}

.lockedLevel {
  cursor: grab,
}

.levelTooltip {
  font-family: 'luckiest_guyregular';
  border: none;
  background: none;
  box-shadow: none;
  font-size: 1.5rem;
  top: -0.8rem;
  color: white;
  -webkit-text-stroke: 1px black;
  text-shadow: 0 0 4px black;
  cursor: pointer;
  &::before {
    border: none;
    margin: none
  }
}


.lockedLevelTooltip {
  font-family: 'luckiest_guyregular';
  border: none;
  background: none;
  box-shadow: none;
  font-size: 1.2rem;
  top: -0.6rem;
  color: lightgrey;
  -webkit-text-stroke: 1px black;
  text-shadow: 0 0 4px black;
  &::before {
    border: none;
    margin: none
  }
}

.youAreHereIcon {
  animation: bounce 0.7s ease infinite;
}

@keyframes bounce{
  from {top: 0px;}
  50%  {top: -20px;}
  to   {top: 0px;}
}


.myPolylineStroke {
  stroke-width: 1px;
  stroke: darkred;
  stroke-opacity: 0.4;
}

.myPolyline {
  stroke-width: 5px;
  stroke: darkred;
  stroke-opacity: 0.4;
  stroke-dasharray: 2,14;
}

.myPolylineInner {
  stroke-width: 20px;
  stroke-opacity: 0.1;
  stroke: #a82d0a;
  
}

.myPolylineOuter {
  stroke-width: 30px;
  stroke-opacity: 0.05;
  stroke: #a82d0a;
  stroke-dasharray: 2,14;
}
