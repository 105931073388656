
@mixin cardGeneric {
    border: 0.5rem solid rgb(231, 197, 132);
    border-radius: 1rem;
    flex: 0 0 15rem;
    box-shadow: 2px 1px 5px 1px rgba(0,0,0,0.5);
    position: relative;
    background-image: url('/mapbackground.jpg');
    background-position-x: 50%;
    background-position-y: bottom;
    background-size: cover;
}

.card {
    @include cardGeneric;
    height: 22rem;
    width: 15rem;
}

.cardMini {
    @include cardGeneric;
    height: 15rem;
    width: 15rem;
}

.cardDisabled {
    @include cardGeneric();
    filter: grayscale(1);
    height: 22rem;
    width: 15rem;
}
