
.arenaFullPage {
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    position: fixed;
    z-index: 400;
    display: flex;
    align-items: center;
}

.cardBoardBackground {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-image: url('/arena.jpg');
    background-size: cover;
    background-position: center;
    border-top: 5px solid black;
    border-bottom: 5px solid black;
    width: 100%;
    height: 60%;
}

.cardBoard {
    width: 100%;
    height: 100%;    
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-size: cover;
    perspective: 20rem;
}

.fakeCard {
    width: 15rem;
    height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid black;
    position: relative;
}

.portalContainerOff {
    margin: 1rem;
}

.portalContainer {
    margin: 1rem;
}
.portalContainer::after {
    position: absolute;
    background: lightgreen;
    padding: 1rem;
    padding: 1rem;
    bottom: 0;
    right: 0;
    content: "in portal";
}

.cardsRow {
    display: flex;
    overflow: auto;
    border: 3px solid black;
    width: 100%;
    justify-content: center;
    position: relative;
}

.cardsRowIsDragging {
    display: flex;
    overflow: auto;
    border: 3px solid black;
    width: 100%;
    justify-content: center;
    position: relative;
    background-color: rgba(0, 210, 210, 0.3);
}


.cardsRowAnimate {
    display: flex;
    overflow: auto;
    border: 3px solid black;
    width: 100%;
    justify-content: center;
    &>:nth-child(0){
        animation-name: slide-in;
        animation-duration: 1s;
        animation-delay: 0s;
        position: absolute;
    }
    
    &>:nth-child(1){
        animation-name: slide-in;
        animation-duration: 1s;
        animation-delay: 0.1s;
    }
    
    &>:nth-child(2){
        animation-name: slide-in;
        animation-duration: 1s;
        animation-delay: 0.2s;
    }
    &>:nth-child(3){
        animation-name: slide-in;
        animation-duration: 1s;
        animation-delay: 0.3s;
    }
    &>:nth-child(4){
        animation-name: slide-in;
        animation-duration: 1s;
        animation-delay: 0.4s;
    }
    &>:nth-child(5){
        animation-name: slide-in;
        animation-duration: 1s;
        animation-delay: 0.5s;
    }
    &>:nth-child(6){
        animation-name: slide-in;
        animation-duration: 1s;
        animation-delay: 0.6s;
    }
}

@keyframes slide-in {
    from {
        left: -2000px;
        opacity: 0;
        transform: rotateY(45deg);

    }
    to {
        left: 0px;
        opacity: 1;
        transform: rotateY(0deg);
    }
}