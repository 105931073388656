
.wonRoundWrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: rgba(0,48,64,0.7);
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wonRound {
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 45rem;
    height: 30rem;
    background-image: url(/ui/main-dialog-no-button.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding-top: 5rem;
    cursor: pointer;
}

.wonRoundNextButton {
    background-image: url(/ui/dark-green-button.png);
    color: white;
    width: 272px;
    height: 114px;
    font-family: 'luckiest_guyregular';
    font-weight: normal;
    font-size:2rem;
    text-align: center;
    display: block;
    p {
        position: relative;
        top: -0.5rem;
    }
    position: relative;
    margin-right: 1.5rem;
}

.wonRoundCreateAccount {
    background-image: url(/ui/cloud-upload-button.png);
    width: 115px;
    height: 114px;
}

.wonRoundSeparator {
    margin: auto;
}
.wonRoundText {
    position: relative;
    margin: 0;
    font-family: 'luckiest_guyregular';
    font-weight: normal;
    font-size:3rem;
}

.bottomBar {
    width: 700px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.wonRoundDetails {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28rem;
    height: 15rem;
    font-size: 1.3rem;
    margin: 0;
    ul {
      list-style-type: none;
      padding: 0;
      width: 25rem;
      li {
          display: block;
          height: 1.8rem;
          font-size: 1.2rem;
      }
    }
}

.cardIcon {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    background-image: url(/ui/card-icon.png);
    background-position: center;
    background-size: cover;
    margin-right: 0.2rem;
}

.energyIcon {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    background-image: url(/ui/energy-icon.png);
    background-position: center;
    background-size: cover;
    margin-right: 0.2rem;
}

.strIcon {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    background-image: url(/ui/str-icon.png);
    background-position: center;
    background-size: cover;
    margin-right: 0.2rem;
}

.noDateIcon {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    background-image: url(/ui/no-date-icon.png);
    background-position: center;
    background-size: cover;
    margin-right: 0.2rem;
}

.heartIcon {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    background-image: url(/ui/heart-icon.png);
    background-position: center;
    background-size: cover;
    margin-right: 0.2rem;
}

.indicator {
    display: inline-block;
    width: 4rem;
}
