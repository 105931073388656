
@mixin categoryAreaCommon {
    border-radius: 1rem;
    margin: 0.5rem;
    font-size: 1.2rem;
    color: white;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
}

.categoryArea {
    @include categoryAreaCommon;
    border: 8px solid #c0b070;
}

.categoryAreaOver {
    @include categoryAreaCommon;
    border: 8px solid #f0e0a0;
}

.categoryAreaTitle {
    background-color: rgba(68, 68, 68, 0.7);
    border-top: 1px solid black;
    border-bottom: 1px solid white;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
    margin: 0px;
    padding: 0.5rem;
    text-align: center;
}

.categoryLeftDate {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 5rem;
    background-color: rgba(0,0,0,0.1)
}

.categoryCentralZone {
    display: flex;
}

.categoryRightDate {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    background-color: rgba(0,0,0,0.1);
    min-width: 5rem;
}

.categoryAreaMain {
    display: flex;
    justify-content: space-between;
    align-self: center;
    height: calc(100% - 2rem);
}

@mixin categoryCheck {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'luckiest_guyregular';
    background-color: rgba(0,0,0,0.5);
    font-size: 4rem;
}

.categoryIsOk {
    @include categoryCheck;
    color: rgba(70, 220, 90, 0.9);
    -webkit-text-stroke: 3px rgba(40, 140, 50, 0.9);
}

.categoryIsWrong {
    @include categoryCheck;
    color: rgba(220, 70, 90, 0.9);
    -webkit-text-stroke: 3px rgba(140, 40, 50, 0.9);
}
