
.Button {
    font-size: 2rem;
    padding: 0rem 2rem 1rem 2rem;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    color: white;
    width: 25rem;
    height: 7rem;
    background-size: contain;
    background-image: url(/ui/wood-button.png);
    background-color: Transparent;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    cursor:pointer;
    overflow: hidden;
    outline:none;
}

.buttonTextWhite {
    font-family: 'luckiest_guyregular';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow:   0px -1px 0 #212121,  
                   0px -1px 0 #212121,
                   0px  1px 0 #212121,
                   0px  1px 0 #212121,
                  -1px  0px 0 #212121,  
                   1px  0px 0 #212121,
                  -1px  0px 0 #212121,
                   1px  0px 0 #212121,
                  -1px -1px 0 #212121,  
                   1px -1px 0 #212121,
                  -1px  1px 0 #212121,
                   1px  1px 0 #212121,
                  -1px  3px 0 #212121,
                   0px  3px 0 #212121,
                   1px  3px 0 #212121,
                   0 3px 1px rgba(0,0,0,.1),
                   0 0 1px rgba(0,0,0,.1),
                   0 1px 3px rgba(0,0,0,.3),
                   0 2px 1px rgba(0,0,0,.2),
                   0 3px 3px rgba(0,0,0,.25),
                   0 4px 4px rgba(0,0,0,.2),
                   0 5px 5px rgba(0,0,0,.15);

                   color: lightgray;
                   position: relative;
}
.buttonTextGrey {
                   height: 50%; 
                   position: absolute;
                   color: white;
                   overflow: hidden;   
}