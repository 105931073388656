
.chronometer {
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('/ui/timer-full.png');
    width: 680px;
    height: 100%;
    color: rgb(131, 241, 255);
    font-size: 1.8rem;
    font-weight: bolder;
    padding-left: 50px;
}

.chronometerText {
    display: inline-block;
    position: relative;
    top: 27%;
    left: -0.7rem;
    width: 100%;
}