// cSpell:disable

.cardType{
  position: absolute;
  z-index: 3;
  top: 2px;
  right: 0px;
  margin: 0.1rem;
  border-radius: 2rem;
  border: 1px solid black;
}

.cardTypeCaption {
  position: absolute;
  border: 1px solid black;
  border-radius: 0.3rem;
  background-color: rgba(215, 212, 197, 0.8);
  font-size: 0.6rem;
  width: 4rem;
  bottom: -0.3rem;
  right: -0.5rem;
  text-align: center;
  font-weight: bold;
}

@mixin card-type-generic {
  border-radius: 1rem;
  width: 50px;
  height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0,0,0,0);
}

.cardTypeDivine {
  @include card-type-generic;
  background-image: url('/card-types/algiz-prudence-conscience-divin.png');
}

.cardTypeCommunication {
  @include card-type-generic;
  background-image: url('/card-types/ansuz-communication-chant-inspiration.png');
}

.cardTypeBeginning {
  @include card-type-generic;
  background-image: url('/card-types/berkana-croissance-union-naissance.png');
}

.cardTypeBirth {
  @include card-type-generic;
  background-image: url('/card-types/berkana-croissance-union-naissance.png');
}

.cardTypeOrder {
  @include card-type-generic;
  background-image: url('/card-types/dagaz-equilibre-ordre-accord.png');
}

.cardTypeTreaty {
  @include card-type-generic;
  background-image: url('/card-types/dagaz-equilibre-ordre-accord.png');
}

.cardTypeTravel {
  @include card-type-generic;
  background-image: url('/card-types/ehwaz-mouvement-voyage.png');
}

.cardTypeDeath {
  @include card-type-generic;
  background-image: url('/card-types/eihwas-renaissance-mort.png');
}

.cardTypeFire {
  @include card-type-generic;
  background-image: url('/card-types/feuh-argent-pouvoir.png');
}

.cardTypeEconomics {
  @include card-type-generic;
  background-image: url('/card-types/feuh-argent-pouvoir.png');
}

.cardTypeMoney {
  @include card-type-generic;
  background-image: url('/card-types/feuh-argent-pouvoir.png');
}

.cardTypePower {
  @include card-type-generic;
  background-image: url('/card-types/feuh-argent-pouvoir.png');
}

.cardTypeTrade {
  @include card-type-generic;
  background-image: url('/card-types/gebo-don-echange-connexion.png');
}

.cardTypeGift {
  @include card-type-generic;
  background-image: url('/card-types/gebo-don-echange-connexion.png');
}

.cardTypeDestruction {
  @include card-type-generic;
  background-image: url('/card-types/hagalaz-destruction-evolution.png');
}

.cardTypeEvolution {
  @include card-type-generic;
  background-image: url('/card-types/hagalaz-destruction-evolution.png');
}

.cardTypeProsperity {
  @include card-type-generic;
  background-image: url('/card-types/ingwaz-plaisir-prosperite.png');
}

.cardTypeCelebration {
  @include card-type-generic;
  background-image: url('/card-types/ingwaz-plaisir-prosperite.png');
}

.cardTypeStuck {
  @include card-type-generic;
  background-image: url('/card-types/isa-immobilite-fondation-ego.png');
}

.cardTypeFoundations {
  @include card-type-generic;
  background-image: url('/card-types/isa-immobilite-fondation-ego.png');
}

.cardTypeEgo {
  @include card-type-generic;
  background-image: url('/card-types/isa-immobilite-fondation-ego.png');
}

.cardTypeEarth {
  @include card-type-generic;
  background-image: url('/card-types/jera-terre-cycle.png');
}

.cardTypeCycle {
  @include card-type-generic;
  background-image: url('/card-types/jera-terre-cycle.png');
}

.cardTypeCraft {
  @include card-type-generic;
  background-image: url('/card-types/kenaz-feu-transformation.png');
}

.cardTypeEnergy {
  @include card-type-generic;
  background-image: url('/card-types/kenaz-feu-transformation.png');
}

.cardTypeTransformation {
  @include card-type-generic;
  background-image: url('/card-types/kenaz-feu-transformation.png');
}

.cardTypeArt {
  @include card-type-generic;
  background-image: url('/card-types/laguz-emotion-source-vie.png');
}

.cardTypeLife {
  @include card-type-generic;
  background-image: url('/card-types/laguz-emotion-source-vie.png');
}

.cardTypeEmotion {
  @include card-type-generic;
  background-image: url('/card-types/laguz-emotion-source-vie.png');
}

.cardTypeSource {
  @include card-type-generic;
  background-image: url('/card-types/laguz-emotion-source-vie.png');
}

.cardTypeMan {
  @include card-type-generic;
  background-image: url('/card-types/mannaz-homme-societe-groupe.png');
}

.cardTypeSociety {
  @include card-type-generic;
  background-image: url('/card-types/mannaz-homme-societe-groupe.png');
}

.cardTypeGroup {
  @include card-type-generic;
  background-image: url('/card-types/mannaz-homme-societe-groupe.png');
}

.cardTypeConstraint {
  @include card-type-generic;
  background-image: url('/card-types/naudhiz-embarras-contrainte.png');
}

.cardTypeScandale {
  @include card-type-generic;
  background-image: url('/card-types/naudhiz-embarras-contrainte.png');
}

.cardTypeElder {
  @include card-type-generic;
  background-image: url('/card-types/othila-ancetre-pouvoir.png');
}

.cardTypeMaster {
  @include card-type-generic;
  background-image: url('/card-types/othila-ancetre-pouvoir.png');
}

.cardTypeGame {
  @include card-type-generic;
  background-image: url('/card-types/perth-jeux-secret-sport.png');
}

.cardTypeSport {
  @include card-type-generic;
  background-image: url('/card-types/perth-jeux-secret-sport.png');
}

.cardTypeSecret {
  @include card-type-generic;
  background-image: url('/card-types/perth-jeux-secret-sport.png');
}

.cardTypeCulture {
  @include card-type-generic;
  background-image: url('/card-types/raidho-culture-science.png');
}

.cardTypeScience {
  @include card-type-generic;
  background-image: url('/card-types/raidho-culture-science.png');
}

.cardTypeSun {
  @include card-type-generic;
  background-image: url('/card-types/sowelu-soleil-vie-guide.png');
}

.cardTypeLifeGiver {
  @include card-type-generic;
  background-image: url('/card-types/sowelu-soleil-vie-guide.png');
}

.cardTypeGuide {
  @include card-type-generic;
  background-image: url('/card-types/sowelu-soleil-vie-guide.png');
}

.cardTypeProtection {
  @include card-type-generic;
  background-image: url('/card-types/thurisaz-defense-protection.png');
}

.cardTypeDefense {
  @include card-type-generic;
  background-image: url('/card-types/thurisaz-defense-protection.png');
}

.cardTypeConquest {
  @include card-type-generic;
  background-image: url('/card-types/tiwaz-conquete-courage-justice.png');
}

.cardTypeSky {
  @include card-type-generic;
  background-image: url('/card-types/tiwaz-conquete-courage-justice.png');
}

.cardTypeWar {
  @include card-type-generic;
  background-image: url('/card-types/tiwaz-conquete-courage-justice.png');
}

.cardTypeCourage {
  @include card-type-generic;
  background-image: url('/card-types/tiwaz-conquete-courage-justice.png');
}

.cardTypeJustice {
  @include card-type-generic;
  background-image: url('/card-types/tiwaz-conquete-courage-justice.png');
}

.cardTypeHealth {
  @include card-type-generic;
  background-image: url('/card-types/uruz-force-sante.png');
}

.cardTypeStrength {
  @include card-type-generic;
  background-image: url('/card-types/uruz-force-sante.png');
}

.cardTypeBalance {
  @include card-type-generic;
  background-image: url('/card-types/wunjo-equilibre-fraternite.png');
}

.cardTypeBrotherhood {
  @include card-type-generic;
  background-image: url('/card-types/wunjo-equilibre-fraternite.png');
}

.cardTypeIconConflict {
  @include card-type-generic;
  background-image: url('/card-types/hagalaz-destruction-evolution.png');
}

.cardTypeIconDeath {
  @include card-type-generic;
  background-image: url('/card-types/eihwas.png');
}

.cardTypeIconNaissance {
  @include card-type-generic;
  background-image: url('/card-types/berkana-croissance-union-naissance.png');
}

.cardTypeIconEvenement {
  @include card-type-generic;
  background-image: url('/card-types/perth-jeux-secrets-sport.png');
}

.cardTypeIconOeuvre {
  @include card-type-generic;
  background-image: url('/card-types/raidho-culture-science.png');
}

.cardTypeIconDecouverte {
  @include card-type-generic;
  background-image: url('/card-types/kenaz-feu-transformation.png');
}

.cardTypeWisdom {
  @include card-type-generic;
  background-image: url('/card-types/othila-ancetre-pouvoir.png');
}
