
.strengthBar {
    background-image: url('/ui/strength-bar.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 200px;
    height: 100%;
}

.strengthBarText {
    display: inline-block;
    position: relative;
    left:6.2rem;
    top: 27%;
    color: rgb(255, 253, 149);
    font-size: 2rem;
    font-weight: bolder;
    text-align: center;

}