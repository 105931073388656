.collectionHeader {
    color: rgba(180,160,120,1);
    margin-left: 4rem;
}

.cardWrapper {
    width: 18rem;
    height: 22rem;
    margin: 2rem; 
}

.viewCards {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    overflow-y: auto;
}