
.cardBoardBackground {
    display: flex;
    justify-content: space-around;
    align-items: center;
/*    background-image: url('/prehistoric.jpg');*/
    background-image: url('/blackboard.jpg');
    background-size: cover;
    border-top: 5px solid black;
    border-bottom: 5px solid black;
    transition: all;
    transition: ease-in;
    transition-duration: 1s;
    width: 100%;
}
.cardBoardBackgroundShowMissMessage {
    bottom: -20rem;
    position: relative;
    z-index: 9000;
}


.cardBoard {
    width: 100%;
    height: 100%;    
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-size: cover;
    perspective: 20rem;
}

.cardBoardAnimate {
    &>:nth-child(2){
        animation-name: slide-in;
        animation-duration: 1s;
        animation-delay: 0s;
    }
    
    &>:nth-child(4){
        animation-name: slide-in;
        animation-duration: 1s;
        animation-delay: 0.1s;
    }
    
    &>:nth-child(6){
        animation-name: slide-in;
        animation-duration: 1s;
        animation-delay: 0.2s;
    }
    &>:nth-child(8){
        animation-name: slide-in;
        animation-duration: 1s;
        animation-delay: 0.3s;
    }
    &>:nth-child(10){
        animation-name: slide-in;
        animation-duration: 1s;
        animation-delay: 0.4s;
    }
    &>:nth-child(12){
        animation-name: slide-in;
        animation-duration: 1s;
        animation-delay: 0.5s;
    }
    &>:nth-child(14){
        animation-name: slide-in;
        animation-duration: 1s;
        animation-delay: 0.6s;
    }
}

@keyframes slide-in {
    from {
        left: -2000px;
        opacity: 0;
        transform: rotateY(45deg);

    }
    to {
        left: 0px;
        opacity: 1;
        transform: rotateY(0deg);
    }
}

.cardsRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: rgba(0,0,0,0);
    transition: background-color 0.5s ease;
    border-radius: 1rem;
}

.cardsRowIsDragging {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: rgba(210,160,80,0.3);
    transition: background-color 0.5s ease;
    border-radius: 1rem;
}
