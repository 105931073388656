.appBoard {
  position: fixed;
  background-color: rgba(0,0,0,0.3);
  width: 100vw;
  height: 100vh;
  z-index: 500;
  top: 0;
  left: 0;
}

.lives {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.checkButtonWrapper {
  margin-top: 2%;
}

.cardBoard {
  height: 60vh;
  display: flex;
  justify-content: center;
}

.numberOfAttempts {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  color: white;
}

.barsContainer {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.timerBar {
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('/ui/timer-full.png');
  width: 1000px;
  height: 100%;
  margin: auto;
}

.avatarBar {
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatarOuter {
  border-radius: 5rem;
  box-shadow: 8px 8px 0px 0px rgba(0,0,0,0.4);
  background: white;
  border: 3px solid#c0feff;
  width: 5.5rem;
  height: 5.5rem;
}

.avatar {
  background-image: url('/avatars/1_prehistorique.png');
  box-shadow: -1px 5px 4px 4px rgba(0,0,0,0.4);
  border: 4px solid #7091a3;
  width: 5rem;
  height: 5rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5rem;
}

.avatarLevel {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  right: 1.5rem;
  bottom: -2.5rem;
  background: white;
  border-radius: 1rem;
  border: 4px solid #7091a3;
  box-shadow: 4px 4px 0px 0px rgba(0,0,0,0.4);
  font-size: 1rem;
  font-weight: bold;
  color: #31434d;
}

.mainArea {
  height: 100%;
}

.levelHint {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-family: 'luckiest_guyregular';
  font-weight: normal;
  font-size: 2rem;
  position: absolute;
  z-index: 10000;
  color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  text-shadow: 0px 0px 5px black;
  animation: show-level-hint 1s cubic-bezier(.49,.01,1,-0.01);
}

@keyframes show-level-hint {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}