
.modalContent {
    font-family: 'luckiest_guyregular';
    font-size: 2rem;
    height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
