.lernieWrapper {
    z-index: 10000;
}

.lernieOverlay {
    z-index: 10001;
    cursor: pointer;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.lernieBackground {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    animation: fade-to-black 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes fade-to-black {
    0% {
        background-color: rgba(0,0,0,0.0);
    }

    100% {
        background-color: rgba(0,0,0,0.3);
    }
}

@mixin genericLernieSpeaking {
    background-image: url(/lernie-481px.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 400px;
    height: 300px;
    position:fixed;
    bottom: 0px;
    right: 0px;
    z-index: 10000;

}

.slideInBottom {
    @include genericLernieSpeaking;
	-webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slideOutBottom {
    @include genericLernieSpeaking;
	-webkit-animation: slide-out-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-out-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


.shakenLernie {
    @include genericLernieSpeaking;
	-webkit-animation: shake 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: shake 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes shake {
    5% {
        transform: translate3d(6px, 0, 0);
    }
    10%, 35%, 90% {
        transform: translate3d(-3px, 0, 0);
    }

    20%, 45%, 80% {
        transform: translate3d(6px, 0, 0);
    }

    30%, 50%, 15%, 70% {
        transform: translate3d(-12px, 0, 0);
    }

    25%, 40%, 60% {
        transform: translate3d(12px, 0, 0);
    }

    100% {
        transform: translate3d(0px, 0, 0);
    }
}
@-webkit-keyframes slide-in-bottom {
    0% {
        -webkit-transform: translateY(300px);
        transform: translateY(300px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-in-bottom {
    0% {
        -webkit-transform: translateY(300px);
        transform: translateY(300px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}


@-webkit-keyframes slide-out-bottom {
    0% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(300px);
        transform: translateY(300px);
        opacity: 0;
    }
}

@keyframes slide-out-bottom {
    0% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(300px);
        transform: translateY(300px);
        opacity: 0;
    }
}

.bubble {
    background-image: url(/ui/bubble-dot.png);
    width: 32rem;
    height: 16rem;
    background-size: contain;
    background-repeat: no-repeat;
    position: fixed;
    right: 16rem;
    bottom: 3.5rem;
    z-index: 10000;
    -webkit-animation: bubble-text 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: bubble-text 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.bubbleText {
    display: block;
    margin-top:4.5rem;
    margin-left: 1rem;
    width: 21rem;
}

@keyframes bubble-text {
    0% {
        opacity: 0;
    }
    90% {
        opacity: 1;
    }
}

.chrysBubbleWrapper {
    background-image: url(/ui/bubble-blue-2.png);
    width: 664px;
    height: 231px;
    background-size: contain;
    background-repeat: no-repeat;
    position: fixed;
    right: 0rem;
    bottom: 15rem;
    z-index: 10000;
    padding: 3rem;
    -webkit-animation: bubble-text 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: bubble-text 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.chrysBubble {
    font-weight: bold;
    font-size:1.5rem;
    color: #054d79;
    margin-block-start: 0rem;
    margin-block-end: 0rem;
    width: 38rem;
    top: -1rem;
    position: relative;
    padding-left:2rem;
}

.kokkiBubbleWrapper {
    background-image: url(/ui/bubble-red-2.png);
    width: 392px;
    height: 313px;
    background-size: contain;
    background-repeat: no-repeat;
    position: fixed;
    right: 2rem;
    bottom: 16rem;
    z-index: 10000;
    padding: 2rem;
    font-weight: bold;
    font-style: italic;
    font-size:1.5rem;
    color: #790505;
    -webkit-animation: bubble-text 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: bubble-text 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-animation-name: wobble; 
    animation-name: wobble; 

}

.kokkiBubble {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -1rem;
    padding:0px;
    width: 17rem;
    margin-block-start: 0rem;
    margin-block-end: 0rem;
}

@keyframes wobble {
    0% { transform: translateX(0%); } 
    15% { transform: translateX(-25%) rotate(-5deg); } 
    30% { transform: translateX(20%) rotate(3deg); } 
    45% { transform: translateX(-15%) rotate(-3deg); } 
    60% { transform: translateX(10%) rotate(2deg); }
    75% { transform: translateX(-5%) rotate(-1deg); }
    100% { transform: translateX(0%); } 
 } 
 

.prasiBubbleWrapper {
    background-image: url(/ui/bubble-green-2.png);
    width: 350px;
    height: 264px;
    background-size: contain;
    background-repeat: no-repeat;
    position: fixed;
    right: 12rem;
    bottom: 15rem;
    z-index: 10000;
    padding: 2rem 2rem 2rem 2rem;
    font-weight: bold;
    font-size:1.5rem;
    color: #05794d;
    -webkit-animation: bubble-text 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: bubble-text 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.prasiBubble {
    margin-top: 4rem;
    margin-left: 2rem;
    margin-right: 1rem;
    height: 5rem;
    display: flex;
    align-items: center;
}

.lernieHint {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10002;
    top: 0;
    left: 0;
    width: 100vw;
    height: 30vh;
    color: white;
    font-size: 2rem;
    font-family: 'luckiest_guyregular';
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    text-shadow: 0 0 8px rgba(0,0,0,0.6);
    pointer-events:none;
}
