.energyBar {
    background-image: url('/ui/energy.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 200px;
    height: 100%;
}

.energyBarText {
    display: inline-block;
    position: relative;
    left:5.4rem;
    top: 20%;
    color: rgb(228, 183, 117);
    font-size: 2rem;
    font-weight: bolder;
    text-align: center;
}
