.cardDate {
  background-color: #0083a3;
  color: white;
  font-weight: bold;
  padding: 0.2rem 0.6rem;
  transform: rotate(-1deg);
  display: inline-block;
  position: relative;
  transition: 0.5s ease-in-out;
  top: -0.7rem;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
}

.blurredCard {
  color: transparent;
  text-shadow: 0 0 5px rgb(201, 221, 226);
}

.cardDateContainer {
  width:100%;
  text-align: center;
}


.cardDateShowRound {
  transform: rotate(-1deg) scale(1.5);
}