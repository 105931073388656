
.successWrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: rgba(0,48,64,0.7);
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.success {
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 45rem;
    height: 30rem;
    background-image: url(/ui/main-dialog.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding-top: 5rem;
    cursor: pointer;
}

.successSeparator {
    margin: auto;
}
.successText {
    margin: 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-family: 'luckiest_guyregular';
    font-weight: normal;
    font-size:3rem;
}

@mixin medal-generic {
    width: 82px;
    height: 82px;
    display: block;
    background-position: center;
    background-repeat: no-repeat;
}
.successMedalLevel1 {
    @include medal-generic();
    background-image: url(/ui/bronze-1.png);
}
.successMedalLevel2 {
    @include medal-generic();
    background-image: url(/ui/bronze-2.png);
}
.successMedalLevel3 {
    @include medal-generic();
    background-image: url(/ui/bronze-3.png);
}
.successMedalLevel4 {
    @include medal-generic();
    background-image: url(/ui/bronze-4.png);
}
.successMedalLevel5 {
    @include medal-generic();
    background-image: url(/ui/silver-1.png);
}
.successMedalLevel6 {
    @include medal-generic();
    background-image: url(/ui/silver-2.png);
}
.successMedalLevel7 {
    @include medal-generic();
    background-image: url(/ui/silver-3.png);
}
.successMedalLevel8 {
    @include medal-generic();
    background-image: url(/ui/silver-4.png);
}
.successMedalLevel9 {
    @include medal-generic();
    background-image: url(/ui/gold-1.png);
}
.successMedalLevel10 {
    @include medal-generic();
    background-image: url(/ui/gold-2.png);
}
.successMedalLevel11 {
    @include medal-generic();
    background-image: url(/ui/gold-3.png);
}
.successMedalLevel12 {
    @include medal-generic();
    background-image: url(/ui/gold-4.png);
}

.successDetails {
    display: flex;
    padding-top: 2rem;
    justify-content: center;
    align-items: center;
    width: 28rem;
    font-size: 1.3rem;
    margin: 0;
}

.successButtonText {
    font-family: 'luckiest_guyregular';
    font-weight: normal;
    font-size:2rem;
    position: relative;
    bottom: 3.8rem;
    width: 100%;
    text-align: center;
    display: block;
}

.cardIcon {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    background-image: url(/ui/card-icon.png);
    background-position: center;
    background-size: cover;
    margin-right: 0.2rem;
}

.energyIcon {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    background-image: url(/ui/energy-icon.png);
    background-position: center;
    background-size: cover;
    margin-right: 0.2rem;
}

.strIcon {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    background-image: url(/ui/str-icon.png);
    background-position: center;
    background-size: cover;
    margin-right: 0.2rem;
}

.noDateIcon {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    background-image: url(/ui/no-date-icon.png);
    background-position: center;
    background-size: cover;
    margin-right: 0.2rem;
}

.heartIcon {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    background-image: url(/ui/heart-icon.png);
    background-position: center;
    background-size: cover;
    margin-right: 0.2rem;
}

.indicator {
    display: inline-block;
    width: 4rem;
}
