
.wrongOrderWrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: rgba(64,0,0,0.4);
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    padding-top: 2rem;
}

.wrongOrder {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 730px;
    height: 317px;

    background-image: url(/ui/top-dialog-no-button.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.wrongOrderText {
    position: relative;
    top: -1rem;
    margin: 0;
    font-family: 'luckiest_guyregular';
    font-weight: normal;
    font-size:3rem;
}

.wrongOrderDetails {
    display: inline-block;
    font-size: 1rem;
    margin: 0;
    margin-block-start: 0rem;
    margin-block-end: 0rem;
    top: -2rem;
    position: relative;
    ul {
      width: 25rem;
      list-style-type: none;
      padding: 0; 
      li {
        width: 100%;
        text-align: center;
        display: inline-block;
        padding-top: 1rem;
        font-size: 1.2rem;
      }
    }
}

.wrongOrderHints{
    display: inline-block;
    font-size: 0.7rem;
}

.heartIcon {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    background-image: url(/ui/heart-icon.png);
    background-position: center;
    background-size: cover;
}