.livesBar {
    background-image: url('/ui/lives.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 200px;
    height: 100%;
}

.livesBarText {
    display: inline-block;
    position: relative;
    left:6.5rem;
    top: 24%;
    color: rgb(241, 207, 207);
    font-size: 2rem;
    font-weight: bolder;
}
