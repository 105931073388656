.cardDescriptionContainer {
  border: 1px solid black;
  border-radius: 10px;
  height: 6.5rem;
  margin: -1rem 0.5rem 0.5rem 0.5rem;
  padding: 0rem 0.3rem;
  background-color: #e5e5d5;
  position: relative;
  width: 87%;
  top: 0.5rem;
}

.cardDescription {
  font-size: 0.8rem;
  color: darkslategray;
  padding: 0.2rem 0.3rem;
}

.cardDescriptionWrapper {
  display: flex;
  height: 6.5rem;
  width: 95%;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}