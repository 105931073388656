
@mixin genericTooltip {
  font-family: 'luckiest_guyregular';
  border: none;
  background: none;
  box-shadow: none;
  -webkit-text-stroke: 1px black;
  text-shadow: 0 0 4px black;
  &::before {
    border: none;
    margin: none
  }
  z-index: 400;
}

@mixin generic-on {
  @include genericTooltip;
  font-size: 1.2rem;
  color: white;
  cursor: pointer;
}

@mixin generic-off {
  @include genericTooltip;
  font-size: 0.8rem;
  color: gray;
  cursor: grab,
}

.default {
  @include generic-on;
  top: -0.8rem;
}

.default-off {
  @include generic-off;
  top: -0.6rem;
}

.desert-card {
  @include generic-on;
  top: 0.6rem;
}

.desert-card-off {
  @include generic-off;
  top: 0.6rem;
}

.desert {
  @include generic-on;
  top: 0.6rem;
}

.desert-off {
  @include generic-off;
  top: 0.6rem;
}

.desert-life {
  @include generic-on;
  top: 0.6rem;
}

.desert-life-off {
  @include generic-off;
  top: 0.6rem;
}

.desert-energy {
  @include generic-on;
  top: 0.6rem;
}

.desert-energy-off {
  @include generic-off;
  top: 0.6rem;
}

.desert-time {
  @include generic-on;
  top: 0.6rem;
}

.desert-time-off {
  @include generic-off;
  top: 0.6rem;
}

.desert-save {
  @include generic-on;
  top: 0.6rem;
}

.desert-save-off {
  @include generic-off;
  top: 0.6rem;
}

.desert-hidden {
  @include generic-on;
  opacity: 0.5;
  top: 0.6rem;
}

.desert-hidden-off {
  @include generic-off;
  opacity: 0.5;
  top: 0.6rem;
}


.desert-crossroads {
  @include generic-on;
  top: 2.6rem;
}

.desert-crossroads-off {
  @include generic-off;
  top: 2.4rem;
}

.desert-large-arena {
  @include generic-on;
  top: 2.6rem;
}

.desert-large-arena-off {
  @include generic-off;
  top: 2.4rem;
}

.forest {
  @include generic-on;
  top: 0.6rem;
}

.forest-off {
  @include generic-on;
  top: 0.6rem;
}

.level-special {
  @include generic-on;
  top: -0.8rem;
}

.level-special-off {
  @include generic-off;
  top: -0.6rem;
}

.jungle-crossroad {
  @include generic-on;
  top: 2.8rem;
}

.jungle-crossroad-off {
  @include generic-off;
  top: 2.8rem;
}

.jungle-leave {
  @include generic-on;
  top: 0rem;
  left: 0.3rem;
}

.jungle-leave-off {
  @include generic-off;
  top: 0rem;
  left: 0.3rem;
}

.beach{
  @include generic-on;
  top: 0.2rem;
}

.beach-off {
  @include generic-off;
  top: 0rem;
}

.jungle {
  @include generic-on;
  top: -0.8rem;
}

.jungle-off {
  @include generic-off;
  top: -0.6rem;
}

.jungle-leave2 {
  @include generic-on;
  top: 0rem;
  left: -0.5rem;
}

.jungle-leave2-off {
  @include generic-off;
  top: 0rem;
  left: -0.5rem;
}

.jungle-exclamation {
  @include generic-on;
  top: -0.2rem;
  left: -0.5rem;
}

.jungle-exclamation-off {
  @include generic-off;
  top: -0.2rem;
  left: -0.5rem;
}

.jungle-trex {
  @include generic-on;
  top: 0rem;
  left: 0.5rem;
}

.jungle-trex-off {
  @include generic-off;
  top: 0rem;
  left: 0.5rem;
}

.jungle-fight {
  @include generic-on;
  top: -0.2rem;
  left: -0.5rem;
}

.jungle-fight-off {
  @include generic-off;
  top: -0.2rem;
  left: -0.5rem;
}