
.card {
    border: 0.5rem solid rgba(180,160,120,1);
    border-radius: 1rem;
    flex: 0 0 15rem;
    box-shadow: 2px 1px 5px 1px rgba(0,0,0,0.5);
    position: relative;
    background-image: url('/mapbackground.jpg');
    background-position-x: 50%;
    background-position-y: bottom;
    background-size: cover;
    height: 22rem;
}